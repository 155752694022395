/* eslint-disable react/no-did-update-set-state */
import { connect } from 'react-redux';

import { ProductType } from 'SourceComponent/Product/Product.config';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductContainer as SourceProductContainer,
} from 'SourceComponent/Product/Product.container';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform, transformParameters } from 'Util/Product/Transform';

export { mapDispatchToProps } from 'SourceComponent/Product/Product.container';

/** @namespace Satisfly/Component/Product/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs,
});

/** @namespace Satisfly/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    __construct(props) {
        const { parameters, product } = props;

        super.__construct?.(props);

        // TODO: There is a strange error when type isn't compatible with the same type.
        // Probably this is related to the fact that the class is generic.
        // Need to investigate this later.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.state = {
            // Used for customizable & bundle options
            enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
            selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),
            addToCartTriggeredWithError: false,
            // Used for downloadable
            downloadableLinks: [],

            quantity: product?.stock_item?.qty_increments || 1,

            // Used to add to the base price a selected option prices
            adjustedPrice: {},

            // Used for configurable product - it can be ether parent or variant
            selectedProduct: null,
            parameters,
            unselectedOptions: [],
            currentProductSKU: '',
            activeProduct: null,
            packageQuantity: this.props?.product?.package?.[0]?.package_qty || 1,
        };
    }

    componentDidUpdate(
        prevProps,
        prevState,
    ) {
        const {
            enteredOptions,
            selectedOptions,
            downloadableLinks,
        } = this.state;
        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            downloadableLinks: prevDownloadableLinks,
        } = prevState;

        if (
            enteredOptions !== prevEnteredOptions
            || selectedOptions !== prevSelectedOptions
            || downloadableLinks !== prevDownloadableLinks
        ) {
            this.updateAdjustedPrice();
        }

        const { product } = this.props;
        const { product: prevProduct } = prevProps;

        if (product !== prevProduct) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.updateSelectedValues();
        }
    }

    getDefaultPackageQuantity() {
        const { product: { package: productPackage } } = this.props;
        const packageQty = productPackage[0].package_qty;

        return packageQty;
    }

    getMagentoProduct(isPackageProduct = false) {
        const {
            quantity,
            packageQuantity,
            enteredOptions,
            selectedOptions,
            downloadableLinks,
            parameters,
        } = this.state;

        const { product, product: { attributes = {} } } = this.props;
        const configurableOptions = transformParameters(parameters, attributes);

        return magentoProductTransform(
            ADD_TO_CART,
            product,
            isPackageProduct ? packageQuantity : quantity,
            enteredOptions,
            [...selectedOptions, ...downloadableLinks, ...configurableOptions],
            isPackageProduct
        );
    }

    async addToCart(isPackageProduct = false) {
        this.updateSelectedValues();
        const { showError } = this.props;
        const { packageQuantity } = this.state;

        if (this.hasError()) {
            return;
        }

        const { addProductToCart, cartId } = this.props;

        if (typeof packageQuantity === 'object' && Object.keys(packageQuantity)?.length > 0) {
            const products = this.getMagentoProduct();
            const packageProducts = this.getMagentoProduct(true);

            if (packageProducts?.length) {
                await addProductToCart({ products: packageProducts, cartId })
                    .catch(
                    /** @namespace Satisfly/Component/Product/Container/ProductContainer/addToCart/addProductToCart/catch */
                        (error) => {
                            if (error) {
                                showError(error);
                            }
                        },
                    );
            }

            if (products?.length) {
                await addProductToCart({ products, cartId })
                    .then(
                    /** @namespace Satisfly/Component/Product/Container/ProductContainer/addToCart/then/catch/addProductToCart/then */
                        () => {
                            this.clearQty();
                        }
                    )
                    .catch(
                        /** @namespace Satisfly/Component/Product/Container/ProductContainer/addToCart/then/catch */
                        (error) => {
                            if (error) {
                                showError(error);
                            }
                        },
                    );
            }
        } else {
            const products = this.getMagentoProduct(isPackageProduct);

            await addProductToCart({ products, cartId })
                .then(
                    /** @namespace Satisfly/Component/Product/Container/ProductContainer/addToCart/then/catch/addProductToCart/then */
                    () => {
                        this.clearQty();
                    }
                )
                .catch(
                    /** @namespace Satisfly/Component/Product/Container/ProductContainer/addToCart/then/catch */
                    (error) => {
                        if (error) {
                            showError(error);
                        }
                    },
                );
        }
    }

    clearQty() {
        const { quantity } = this.state;
        const { product } = this.props;

        if (product?.type_id === ProductType.GROUPED) {
            const resetQty = {};

            Object.keys(quantity).forEach((key) => {
                resetQty[key] = 0;
            });

            document.querySelectorAll('#item_qty').forEach((input) => {
                // eslint-disable-next-line no-param-reassign
                input.value = 0;
            });

            this.setState({ quantity: resetQty });
        }
    }

    setPackageQuantity(packageQuantity) {
        if (typeof packageQuantity === 'object') {
            const { packageQuantity: oldQuantity = {} } = this.state;
            this.setState({ packageQuantity: { ...(oldQuantity), ...packageQuantity } });
        } else {
            this.setState({ packageQuantity: +(packageQuantity) });
        }
    }

    containerFunctions = {
        ...this.containerFunctions,
        setPackageQuantity: this.setPackageQuantity.bind(this),
    };

    containerProps() {
        const { packageQuantity } = this.state;

        return {
            ...super.containerProps(),
            packageQuantity,
            ean: this.getEan(),
            categoryPath: this.getCategoryPath(),
        };
    }

    getCategoryPath() {
        const {
            breadcrumbs,
        } = this.props;

        return breadcrumbs?.map((_, i) => (i === breadcrumbs.length - 1 ? null : (breadcrumbs[breadcrumbs.length - 1 - i].name))).join('/');
    }

    getEan() {
        const {
            product: {
                attributes,
            } = {},
        } = this.props;

        return attributes?.ean?.attribute_value || '';
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(
    ProductContainer
);
