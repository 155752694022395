import {
    createRef,
    Suspense,
} from 'react';
import { withRouter } from 'react-router';

import Slider from 'Component/Slider';
import {
    ProductGalleryComponent as SourceProductGalleryComponent,
    VideoPopup,
} from 'SourceComponent/ProductGallery/ProductGallery.component';
import CSS from 'Util/CSS';

import { DESKTOP_GALLERY_HEIGHT, DESKTOP_SILDES_COUNT, MOBILE_SILDES_COUNT } from './ProductGallery.config';

import './ProductGallery.override.style';

/** @namespace Satisfly/Component/ProductGallery/Component */
export class ProductGalleryComponent extends SourceProductGalleryComponent {
    state = {
        scrollEnabled: true,
        slidesCount: DESKTOP_SILDES_COUNT,
        prevZoom: false,
    };

    galleryWrapperRef = createRef();

    componentDidMount() {
        this.updateSharedDestinationElement();
        window.addEventListener('resize', this.calculateGallerySize);
        window.addEventListener('scroll', this.handleGalleryScroll);
    }

    componentDidUpdate(prevProps) {
        const {
            productId,
            location: { pathname = '' } = {},
            sliderRef,
            isImageZoomPopupActive,
            isMobile,
        } = this.props;

        const {
            productId: prevProductId,
            location: { pathname: prevPathname = '' } = {},
        } = prevProps;

        const { prevZoom } = this.state;

        if (productId !== prevProductId) {
            this.updateSharedDestinationElement();
        }

        if (sliderRef?.current?.draggableRef && pathname !== prevPathname) {
            CSS.setVariable(
                sliderRef.current.draggableRef,
                'animation-speed',
                0,
            );
        }

        if (isImageZoomPopupActive !== prevZoom) {
            this.handleZoomChange(isImageZoomPopupActive);
        }

        if (isMobile) {
            this.changeSlidesCount(MOBILE_SILDES_COUNT);
        } else {
            this.changeSlidesCount(DESKTOP_SILDES_COUNT);
        }

        // this.handleOverflow();
    }

    handleOverflow() {
        const { isImageZoomPopupActive } = this.props;

        const selector = document.body;

        if (!isImageZoomPopupActive) {
            return selector.style.overflow === 'initial';
        }

        return selector.classList.add('hidden');
    }

    changeSlidesCount(count) {
        this.setState({ slidesCount: count });
    }

    handleGalleryScroll = this._handleGalleryScroll.bind(this);

    _handleGalleryScroll() {
        const { isMobile, isImageZoomPopupActive } = this.props;

        if (isMobile || isImageZoomPopupActive) {
            return;
        }

        const gallerySelector = this.galleryRef?.current;
        const galleryWrapperSelector = this.galleryWrapperRef?.current;
        const galleryY = gallerySelector?.getBoundingClientRect().y;
        const galleryBottom = gallerySelector?.getBoundingClientRect().bottom;
        const galleryWrapperBottom = galleryWrapperSelector?.getBoundingClientRect().bottom;
        const galleryWrapperHeight = galleryWrapperSelector?.getBoundingClientRect().height;

        if (
            galleryY < DESKTOP_GALLERY_HEIGHT
            && !galleryWrapperSelector.classList.contains('fixed')
            && galleryWrapperBottom < galleryBottom
        ) {
            galleryWrapperSelector.classList.add('fixed');
        }

        if (galleryY > DESKTOP_GALLERY_HEIGHT) {
            galleryWrapperSelector.classList.remove('fixed');
            gallerySelector.classList.remove('bottom');
        }

        if (galleryBottom < galleryWrapperBottom) {
            galleryWrapperSelector.classList.remove('fixed');
            gallerySelector.classList.add('bottom');
        }

        if (galleryBottom > galleryWrapperHeight + DESKTOP_GALLERY_HEIGHT) {
            gallerySelector.classList.remove('bottom');
        }
    }

    renderSlides() {
        const { gallery, hoveredItem } = this.props;

        if (hoveredItem) {
            return [hoveredItem, ...gallery.slice(1)].map(this.renderSlide);
        }

        return gallery.map(this.renderSlide);
    }

    renderSlider() {
        const {
            activeImage,
            isZoomEnabled,
            onActiveImageChange,
            isImageZoomPopupActive,
            sliderRef,
            isMobile,
        } = this.props;

        const mods = {
            isImageZoomPopupActive,
            isZoomInCursor: !isImageZoomPopupActive,
        };

        return (
            <div
              ref={ this.imageRef }
              block="ProductGallery"
              elem="SliderWrapper"
            >
                <meta itemProp="image" content={ this.getImageUrl() } />
                <Slider
                  sliderRef={ sliderRef }
                  mix={ { block: 'ProductGallery', elem: 'Slider', mods } }
                  showCounter={ isMobile }
                  showArrows={ false }
                  activeImage={ activeImage }
                  onActiveImageChange={ onActiveImageChange }
                  isInteractionDisabled={ isZoomEnabled }
                  onClick={ this.handleSliderClick }
                  sliderHeight={ isImageZoomPopupActive ? '100%' : 0 }
                  isHeightTransitionDisabledOnMount
                >
                    { this.renderSlides() }
                </Slider>
            </div>
        );
    }

    renderContent() {
        return (
            <>
                { this.renderSlider() }
                { this.renderAdditionalPictures() }
                <Suspense fallback={ null }>
                    <VideoPopup />
                </Suspense>
            </>
        );
    }

    render() {
        const { isImageZoomPopupActive } = this.props;

        return (
            <div block="ProductGallery" ref={ this.galleryRef }>
                { isImageZoomPopupActive ? (
                    this.renderContent()
                ) : (
                    <div block="ProductGallery" elem="WrapperComponent" ref={ this.galleryWrapperRef }>
                        { this.renderContent() }
                    </div>
                ) }
            </div>
        );
    }
}

export default withRouter(ProductGalleryComponent);
