export const ProductPageTabs = {
    INFORMATION: 'information',
    ATTRIBUTES: 'attributes',
    REVIEWS: 'reviews',
    DELIVERY: 'delivery',
};

export const SHIPPING_AND_DELIVERY_CMS_ID = 'shipping-and-delivery';

export const EXCLUDED_ATTRIBUTES = [
    'product_availability',
    'lowest_price_in_period',
    'size',
    'color',
];
