import { Suspense } from 'react';

import CmsBlock from 'Component/CmsBlock';
import ExpandableContent from 'Component/ExpandableContent';
import Loader from 'Component/Loader';
import ProductCard from 'Component/ProductCard';
import ProductGallery from 'Component/ProductGallery';
import RenderWhenVisible from 'Component/RenderWhenVisible';
import TextPlaceholder from 'Component/TextPlaceholder';
import { TextPlaceHolderLength } from 'Component/TextPlaceholder/TextPlaceholder.config';
import {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductInformation,
    ProductLinks,
    ProductPageComponent as SourceProductPageComponent,
    ProductReviewForm,
    ProductReviews,
    ProductTabs,
} from 'SourceRoute/ProductPage/ProductPage.component';
import { LinkedProductType } from 'Store/LinkedProducts/LinkedProducts.type';

import { ProductPageTabs, SHIPPING_AND_DELIVERY_CMS_ID } from './ProductPage.config';

import './ProductPage.override.style';

export {
    Popup,
    ProductActions,
    ProductAttributes,
    ProductInformation,
    ProductLinks,
    ProductReviewForm,
    ProductReviews,
    ProductTabs,
};

/** @namespace Satisfly/Route/ProductPage/Component */
export class ProductPageComponent extends SourceProductPageComponent {
    tabMap = {
        [ ProductPageTabs.INFORMATION ]: {
            name: __('About product'),
            shouldTabRender: () => {
                const { isInformationTabEmpty } = this.props;

                return !isInformationTabEmpty;
            },
            render: (key) => this.renderProductInformationTab(key),
        },
        [ ProductPageTabs.ATTRIBUTES ]: {
            name: __('Details'),
            shouldTabRender: () => {
                const { isAttributesTabEmpty } = this.props;

                return !isAttributesTabEmpty;
            },
            render: (key) => this.renderProductAttributesTab(key),
        },
        [ ProductPageTabs.REVIEWS ]: {
            name: __('Reviews'),
            // Return true since we always show 'Add review' button
            shouldTabRender: () => {
                const { areReviewsEnabled } = this.props;

                return areReviewsEnabled;
            },
            render: (key) => this.renderProductReviewsTab(key),
        },
        [ ProductPageTabs.DELIVERY ]: {
            name: __('Shipping and delivery'),
            shouldTabRender: () => true,
            render: (key) => this.renderShippingAndDeliveryTab(key),
        },
    };

    renderProductBrand() {
        const {
            dataSource: {
                attributes,
            },
        } = this.props;

        if (attributes === null || !attributes?.brand) {
            return null;
        }

        const { brand: { attribute_value: brand = '' } } = attributes;

        return (
            <>
                <meta itemProp="brand" content={ brand } />
                <h4 block="ProductPage" elem="Brand" itemProp="brand">
                    <TextPlaceholder content={ brand } />
                </h4>
            </>
        );
    }

    renderProductName() {
        const { dataSource: { name } } = this.props;

        return (
            <>
                <meta itemProp="name" content={ name } />
                <h1 block="ProductPage" elem="Title">
                    <TextPlaceholder content={ name } length={ TextPlaceHolderLength.MEDIUM } />
                </h1>
            </>
        );
    }

    renderProductMobileNameProp() {
        const { dataSource: { name } } = this.props;

        return <meta itemProp="name" content={ name } />;
    }

    renderProductAttributesTab(key) {
        const {
            activeProduct,
            areDetailsLoaded,
        } = this.props;

        return (
            <RenderWhenVisible fallback={ () => null } key={ key }>
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductAttributes
                      product={ activeProduct }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderProductReviewsTab(key) {
        const {
            dataSource,
            areDetailsLoaded,
        } = this.props;

        return (
            <RenderWhenVisible fallback={ () => null } key={ key }>
                <Suspense fallback={ <Loader /> } key={ key }>
                    <ProductReviews
                      product={ dataSource }
                      areDetailsLoaded={ areDetailsLoaded }
                      key={ key }
                    />
                </Suspense>
            </RenderWhenVisible>
        );
    }

    renderShippingAndDeliveryTab(key) {
        return (
            <RenderWhenVisible fallback={ () => null } key={ key }>
                <ExpandableContent
                  heading={ __('Shipping and delivery') }
                  mix={ { block: 'ProductPage', elem: 'ShippingAndDeliveryTabContent' } }
                  isArrow
                >
                    <CmsBlock identifier={ SHIPPING_AND_DELIVERY_CMS_ID } />
                </ExpandableContent>
            </RenderWhenVisible>
        );
    }

    renderAdditionalSections() {
        const {
            areDetailsLoaded,
        } = this.props;

        return (
            <>
                { this.renderProductTabs() }
                <Suspense fallback={ null }>
                    <ProductLinks
                      linkType={ LinkedProductType.RELATED }
                      title={ __('Related products') }
                      areDetailsLoaded={ areDetailsLoaded }
                    />
                </Suspense>
            </>
        );
    }

    renderProductPageContent() {
        const {
            areDetailsLoaded,
            activeProduct,
            useEmptyGallerySwitcher,
            isVariant,
            hoveredItem,
        } = this.props;

        return (
            <>
                <ProductGallery
                  product={ activeProduct }
                  areDetailsLoaded={ areDetailsLoaded }
                  isWithEmptySwitcher={ useEmptyGallerySwitcher }
                  showLoader={ isVariant }
                  hoveredItem={ hoveredItem }
                />
                { this.renderProductActions() }
            </>
        );
    }

    renderProductActions() {
        const {
            getLink,
            dataSource,
            areDetailsLoaded,
            setActiveProduct,
            setHoveredItem,
            parameters,
            isMobile,
        } = this.props;

        return (
            <div block="ProductPage" elem="ProductActions">
                { !isMobile ? this.renderProductDesktopMainData() : this.renderProductMobileNameProp() }
                <Suspense fallback={ this.renderProductActionsPlaceholder() }>
                    <ProductActions
                      getLink={ getLink }
                      product={ dataSource }
                      parameters={ parameters }
                      areDetailsLoaded={ areDetailsLoaded }
                      setActiveProduct={ setActiveProduct }
                      setHoveredItem={ setHoveredItem }
                    />
                </Suspense>
                { this.renderSubstituteProducts() }
            </div>
        );
    }

    renderSubstituteProducts() {
        const { activeProduct: { substitute_products }, substituteProducts } = this.props;

        if (substitute_products?.length === 0 || substituteProducts?.length === 0) {
            return null;
        }

        return (
            <div block="ProductPage" elem="SubstituteProducts">
                <h5>{ __('Similar products available immediately:') }</h5>
                <div>
                    { substituteProducts.map((product) => (
                        <ProductCard
                          product={ product }
                          key={ product?.id }
                          isSubstituteProduct
                        />
                    )) }
                </div>
            </div>
        );
    }
}
export default ProductPageComponent;
