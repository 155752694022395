/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const ORDER_AVAILABLE_NOW = 'order-available-now';
export const SALABLE_QTY_ERROR_POPUP_ID = 'SalableQtyErrorPopupId';
export const ORDER_MORE_AND_WAIT = 'order-more-and-wait';
export const CART = 'cart';
export const CART_OVERLAY = 'cart_overlay';
export const CHECKOUT = 'checkout';
export const PLP = 'PLP';
export const PDP = 'PDP';
