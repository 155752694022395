import {
    ImageZoomPopupComponent as SourceImageZoomPopupComponent,
} from 'SourceComponent/ImageZoomPopup/ImageZoomPopup.component';

import './ImageZoomPopup.override.style.scss';

/** @namespace Satisfly/Component/ImageZoomPopup/Component */
export class ImageZoomPopupComponent extends SourceImageZoomPopupComponent {
    render() {
        const {
            children,
        } = this.props;

        return (
            <div block="ImageZoomPopup">
                { children }
            </div>
        );
    }
}

export default ImageZoomPopupComponent;
