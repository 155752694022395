import { ProductType } from 'Component/Product/Product.config';
import {
    ProductPriceComponent as SourceProductPriceComponent,
} from 'SourceComponent/ProductPrice/ProductPrice.component';
import { DisplayProductPricesInCatalog } from 'SourceComponent/ProductPrice/ProductPrice.config';
import { formatPrice } from 'Util/Price';

import './ProductPrice.override.style';

/** @namespace Satisfly/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    priceLabelTypeMap = {
        [ProductType.SIMPLE]: __('Starting at'),
        [ProductType.VIRTUAL]: __('Starting at'),
        [ProductType.BUNDLE]: __('Starting at'),
        [ProductType.GROUPED]: __('As Low as'),
        [ProductType.DOWNLOADABLE]: __('Starting at'),
        [ProductType.CONFIGURABLE]: __('As Low as'),
    };

    getCurrentPriceSchema() {
        const {
            isSchemaRequired,
            price: {
                finalPrice: {
                    value: contentPrice = 0,
                } = {},
            } = {},
        } = this.props;

        return isSchemaRequired ? { itemProp: 'lowPrice', content: contentPrice } : {};
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label, isWidget,
        } = this.props;

        return (
            <>
                { this.renderOldPrice() }
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { isWidget && this.renderGrossPrice() }
            </>
        );
    }

    renderPriceWithOrWithoutTax(
        basePrice,
        taxPrice,
        label,
    ) {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <>
                { this.renderPrice(basePrice, label) }
                { this.renderSubPrice(taxPrice) }
            </>
        );
    }

    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted,
                } = {},
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            isWidget,
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0 || isWidget) {
            return null;
        }

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? 'highPrice' : undefined }
            >
                { originalPriceFormatted }
            </del>
        );
    }

    renderGrossPrice() {
        const {
            price: { finalPrice },
            customerPrice,
            priceCurrency,
        } = this.props;

        const { price_incl_tax } = customerPrice ?? { price_incl_tax: null };

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
        } = finalPrice;
        const finalFormattedPrice = price_incl_tax && price_incl_tax < parseFloat(priceValue) ? formatPrice(price_incl_tax, priceCurrency) : priceFormatted;

        return (
            <span aria-label={ __('Current product price') }>
                <data value={ price_incl_tax && price_incl_tax < priceValue ? price_incl_tax : priceValue }>
                    { finalFormattedPrice }
                    { ' ' }
                    (
                    { __('with VAT') }
                    )
                </data>
            </span>
        );
    }

    renderPrice(price, label) {
        const {
            discountPercentage,
            isWidget,
            originalPrice,
            isSimilarProduct,
            customerPrice,
            priceType,
        } = this.props;

        const { price_excl_tax: customerSpecialPrice = null } = customerPrice ?? { price_excl_tax: null };

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0,
            currency,
        } = price;

        // eslint-disable-next-line max-len
        const finalFormattedPrice = customerSpecialPrice && customerSpecialPrice < parseFloat(priceValue) ? formatPrice(customerSpecialPrice, currency) : priceFormatted;

        const { itemProp, content } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        if ((!priceValue && priceValue !== 0)) {
            return null;
        }

        if (isWidget) {
            return (
                <PriceSemanticElementName block="ProductPrice" elem="Price" mods={ { discount: !!discountPercentage } }>
                    <div
                      itemScope
                      block="ProductPrice"
                      elem="WidgetPriceValue"
                    >
                        <meta itemProp={ itemProp } content={ String(content) } />
                        { !!discountPercentage && (
                            <div block="ProductPrice" elem="WidgetDiscountPrice">{ originalPrice?.minRegularPrice.valueFormatted }</div>
                        ) }
                        <div>
                            { this.renderPriceBadge(label) }
                            { finalFormattedPrice }
                        </div>
                        { (isSimilarProduct || priceType === ProductType.GROUPED) && (
                            <div block="ProductPrice" elem="WidgetGrossPrice">
                                { this.renderGrossPrice() }
                            </div>
                        ) }
                    </div>
                </PriceSemanticElementName>
            );
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  itemScope
                  block="ProductPrice"
                  elem="PriceValue"
                  mods={ { length: finalFormattedPrice.length } }
                >
                    <meta itemProp={ itemProp } content={ String(content) } />
                    { finalFormattedPrice }
                    <div>
                        { this.renderGrossPrice() }
                    </div>
                </span>
            </PriceSemanticElementName>
        );
    }

    renderTierPrice() {
        const {
            tierPrice: {
                valueFormatted: tierPriceFormatted,
                value: tierPriceValue,
            },
            price: {
                finalPrice: {
                    value,
                } = {},
            } = {},
            isWidget,
            isDailyPromo,
            isPdp,
        } = this.props;

        if (!tierPriceFormatted || tierPriceValue >= (value || 0) || !!isWidget || !!isDailyPromo || !!isPdp) {
            return null;
        }

        return (
            <p block="ProductPrice" elem="TierPrice">
                { __('As low as') }
                <strong>{ ` ${tierPriceFormatted}` }</strong>
            </p>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0,
                } = {},
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix,
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        const { [priceType]: renderer } = this.pricePreviewRenderMap;

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { isPreview && renderer && renderer() }
                { (!isPreview || !renderer) && this.renderDefaultPrice() }
                { priceType !== ProductType.BUNDLE && this.renderTierPrice() }
                { this.renderSchema() }
            </div>
        );
    }
}

export default ProductPriceComponent;
