/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import ArrowIcon from 'Component/ArrowIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import {
    SliderComponent as SourceSliderComponent,
} from 'SourceComponent/Slider/Slider.component';

/**
 * Slider component
 * @class Slider
 * @namespace Satisfly/Component/Slider/Component */
export class SliderComponent extends SourceSliderComponent {
    renderArrows() {
        const { showArrows, activeImage, children } = this.props;
        const nextIsDisabled = activeImage + 1 === children.length;
        const prevIsDisabled = activeImage === 0;

        if (!showArrows) {
            return null;
        }

        return (
            <>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isPrev: true, isDisabled: prevIsDisabled } }
                  aria-label={ __('Previous') }
                  onClick={ this.goPrev }
                >
                    <ArrowIcon direction={ Directions.LEFT } />
                </button>
                <button
                  block="Slider"
                  elem="Arrow"
                  mods={ { isNext: true, isDisabled: nextIsDisabled } }
                  aria-label={ __('Next') }
                  onClick={ this.goNext }
                >
                    <ArrowIcon direction={ Directions.RIGHT } />
                </button>
            </>
        );
    }

    renderCrumbs() {
        const { children, showCrumbs, slides = [] } = this.props;

        if (!showCrumbs || children.length <= 1) {
            return null;
        }

        return (
            <div block="Slider" elem="Crumbs">
                { slides?.map(({ title }, i) => this.renderCrumb(title, i)) }
            </div>
        );
    }

    renderCrumb(title, i) {
        const {
            activeImage,
            device: {
                isMobile,
            },
        } = this.props;

        const isActive = i === Math.abs(-activeImage);

        return (
            <button
              block="Slider"
              elem="Image"
              onClick={ () => this.changeActiveImage(i) }
              mods={ { isActive } }
              key={ i }
            >
                <div
                  block="Slider"
                  elem="Crumb"
                  mods={ { isActive } }
                >
                    { !isMobile && title }
                </div>
            </button>
        );
    }

    renderCounter() {
        const {
            children,
            showCounter,
            activeImage,
            device,
        } = this.props;

        if (device.isMobile || !showCounter || children.length <= 1) {
            return null;
        }

        return (
            <div
              block="Slider"
              elem="Counter"
            >
                { activeImage + 1 }
                /
                { children.length }
            </div>
        );
    }
}

export default SliderComponent;
