/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';

import { AVAILABILITY_CODE, AvailabilityMap } from 'Component/ProductAvailability/ProductAvailability.config';

import './ProductAvailability.override.style';

/** @namespace Satisfly/Component/ProductAvailability/Component/ProductAvailability */
export const ProductAvailability = ({
    attributes,
    salable_qty,
}) => {
    const productAvailability = attributes?.product_availability?.attribute_options[attributes?.product_availability?.attribute_value];
    const customMods = {
        [AvailabilityMap[productAvailability?.label]]: true,
    };
    const qty = typeof salable_qty === 'number' && salable_qty < 0 ? 0 : salable_qty;

    return (
        <div block="ProductAvailability" elem="Availability" mods={ customMods }>
            <div><span>{ productAvailability?.label }</span></div>
            { productAvailability?.label === AVAILABILITY_CODE && (<div><span>{ qty }</span></div>) }
        </div>
    );
};

ProductAvailability.propTypes = {
    salable_qty: PropTypes.number.isRequired,
    attributes: PropTypes.object.isRequired,
};

export default ProductAvailability;
