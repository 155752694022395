/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
/* eslint-disable */
import { connect } from 'react-redux';

import { SALABLE_QTY_ERROR_POPUP_ID, PLP, PDP } from 'Component/SalableQtyErrorPopup/SalableQtyErrorPopup.config';
import {
    AddToCartContainer as SourceAddToCartContainer,
    mapStateToProps as sourceMapStateToProps,
    mapDispatchToProps as sourceMapDispatchToProps,
} from 'SourceComponent/AddToCart/AddToCart.container';
import { toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { ADD_TO_CART } from 'Util/Product';
import { magentoProductTransform } from 'Util/Product/Transform';

/** @namespace Satisfly/Component/AddToCart/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showOverlay: (overlayKey, payload) => dispatch(toggleOverlayByKey(overlayKey, payload)),
});

export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isModalVisibleInProductPage: state.ConfigReducer.satisfly_backorders_modal_show_in_product_page,
    isModalVisibleInCategory: state.ConfigReducer.satisfly_backorders_modal_show_in_category,
})

/* @namespace Satisfly/Component/AddToCart/Container */
export class AddToCartContainer extends SourceAddToCartContainer {
    static defaultProps = {
        ...super.defaultProps,
        onlyIcon: false,
        label: null,
        isDailyPromo: false,
        url: null,
        type: PLP
    };

    containerProps() {
        const {
            onlyIcon,
            label,
            isDailyPromo,
            url,
        } = this.props;

        return {
            ...super.containerProps(),
            onlyIcon,
            label,
            isDailyPromo,
            url,
        };
    }

    handleButtonClick(e) {
        const { product, quantity, showOverlay, withLink, type, isModalVisibleInProductPage, isModalVisibleInCategory } = this.props;

        // Prevent container Link from triggering redirect
        if (!withLink) {
            e.stopPropagation();
            e.preventDefault();
        }

        if (product?.salable_qty < quantity && ((isModalVisibleInProductPage && type === PDP) || (isModalVisibleInCategory && type === PLP))) {
            showOverlay(SALABLE_QTY_ERROR_POPUP_ID, { products: [{ ...product, quantity }] });
            return;
        } else {
            this.addProductToCart();
        }
    }

    async addProductToCart() {
        const {
            product,
            addToCart,
            updateSelectedValues,
            isPackageProduct,
        } = this.props;

        if (updateSelectedValues) {
            await updateSelectedValues();
        }

        if ((!product || Object.keys(product).length === 0) && !addToCart) {
            return;
        }

        this.setState({ isAdding: true });

        if (!this.validate()) {
            return;
        }

        if (typeof addToCart === 'function') {
            try {
                await addToCart(isPackageProduct);
            } finally {
                this.setState({ isAdding: false });
            }
        } else {
            const {
                quantity,
                cartId,
                fallbackAddToCart,
            } = this.props;
            const magentoProduct = magentoProductTransform(ADD_TO_CART, product, quantity);

            try {
                await fallbackAddToCart({
                    products: magentoProduct,
                    cartId,
                });
            } finally {
                this.setState({ isAdding: false });
            }
        }

        this.setState({ isAdding: false });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddToCartContainer);
