import {
    ProductGalleryThumbnailImageComponent as SourceProductGalleryThumbnailImageComponent,
} from 'SourceComponent/ProductGalleryThumbnailImage/ProductGalleryThumbnailImage.component';

import './ProductGalleryThumbnailImage.override.style';

/** @namespace Satisfly/Component/ProductGalleryThumbnailImage/Component */
export class ProductGalleryThumbnailImageComponent extends SourceProductGalleryThumbnailImageComponent {}

export default ProductGalleryThumbnailImageComponent;
