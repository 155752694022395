/* eslint-disable no-mixed-operators */
/* eslint-disable no-magic-numbers */
import { Suspense } from 'react';

import { FieldType } from 'Component/Field/Field.config';
import ProductPrice from 'Component/ProductPrice';
import { CategoryPageLayout } from 'Route/CategoryPage/CategoryPage.config';
import {
    AddToCart,
    FieldContainer,
    ProductComponent as SourceProductComponent,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';
import { ProductType } from 'SourceComponent/Product/Product.config';
import {
    getPrice,
} from 'Util/Product/Extract';
import { ValidationInputTypeNumber } from 'Util/Validator/Config';

export {
    AddToCart,
    FieldContainer,
    GroupedProductList,
    ProductBundleOptions,
    ProductCompareButton,
    ProductConfigurableAttributes,
    ProductCustomizableOptions,
    ProductDownloadableLinks,
    ProductDownloadableSamples,
    ProductReviewRating,
    ProductWishlistButton,
} from 'SourceComponent/Product/Product.component';

/** @namespace Satisfly/Component/Product/Component */
export class ProductComponent extends SourceProductComponent {
    renderPackagePrice(isPreview) {
        const { getActiveProduct, productPrice } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
            package: productPackage,
            customer_price,
        } = product;

        if (!productPrice || !productPackage?.[0]) {
            return null;
        }

        const regularPrice = productPackage[0]?.regular_price?.amount?.value;
        const regularPriceExclTax = productPackage[0]?.regular_price_excl_tax?.amount?.value;
        const finalPrice = productPackage[0]?.final_price?.amount?.value;
        const finalPriceExclTax = productPackage[0]?.final_price_excl_tax?.amount?.value;
        const percent = (regularPrice - finalPrice) / regularPrice * 100;
        const currency = productPackage[0]?.final_price?.amount?.currency;

        const packagePrice = getPrice({
            minimum_price: {
                final_price: {
                    value: finalPrice,
                    currency,
                },
                final_price_excl_tax: {
                    value: finalPriceExclTax,
                    currency,
                },
                regular_price: {
                    value: regularPrice,
                    currency,
                },
                regular_price_excl_tax: {
                    value: regularPriceExclTax,
                    currency,
                },
                discount: {
                    amount_off: regularPrice - finalPrice,
                    percent_off: percent,
                },
            },
        }, false, {}, type);

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ packagePrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  customerPrice={ customer_price }
                />
            </div>
        );
    }

    renderWishlistButton() {
        const { magentoProduct, isWishlistEnabled } = this.props;

        if (magentoProduct.length === 0 || !isWishlistEnabled) {
            return null;
        }

        return (
            <Suspense fallback={ null }>
                <div block="ProductCard" elem="ProductWishlistWrapper">
                    <span block="ProductCard" elem="ProductWishlistTitle">{ __('Add to shopping list') }</span>
                    <ProductWishlistButton
                      magentoProduct={ magentoProduct }
                      mix={ {
                          block: this.className,
                          elem: 'WishListButton',
                      } }
                    />
                </div>
            </Suspense>
        );
    }

    renderPackageQuantityChanger() {
        const {
            packageQuantity,
            minQuantity,
            maxQuantity,
            setPackageQuantity,
            inStock,
            product: { type_id, package: productPackage },
            isVariant,
            setVariantPackageQuantity,
            isProductPage,
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        const packageQty = productPackage?.[0]?.package_qty;

        return (
            <Suspense fallback={ null }>
                <FieldContainer
                  type={ FieldType.NUMBER_WITH_CONTROLS }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue: isVariant ? 0 : packageQuantity,
                      max: maxQuantity,
                      min: isVariant ? 0 : (packageQty || minQuantity),
                      customRange: packageQty,
                      isVariant,
                  } }
                  validationRule={ {
                      inputType: ValidationInputTypeNumber.NUMERIC,
                      isRequired: true,
                      range: {
                          min: isVariant ? 0 : (packageQty || minQuantity),
                          max: maxQuantity,
                      },
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: isProductPage && isVariant ? setVariantPackageQuantity : setPackageQuantity } }
                  validateOn={ ['onChange'] }
                />
            </Suspense>
        );
    }

    renderPackageAddToCartButton(layout = CategoryPageLayout.GRID) {
        const {
            addToCart,
            inStock,
            packageQuantity,
            getActiveProduct,
            updateSelectedValues,
        } = this.props;

        return (
            <Suspense fallback={ null }>
                <AddToCart
                  mix={ { block: this.className, elem: 'AddToCart' } }
                  addToCart={ addToCart }
                  isDisabled={ !inStock }
                  isIconEnabled
                  layout={ layout }
                  updateSelectedValues={ updateSelectedValues }
                  quantity={ packageQuantity }
                  product={ getActiveProduct() }
                  label={ __('To shopping cart') }
                  isPackageProduct
                  type="PDP"
                />
            </Suspense>
        );
    }

    renderStock() {
        return null;
    }

    renderPrice(isPreview = false) {
        const { getActiveProduct, productPrice, isDailyPromo } = this.props;
        const product = getActiveProduct();

        const {
            type_id: type,
            price_tiers: priceTiers,
            customer_price,
        } = product;

        if (!productPrice) {
            return null;
        }

        return (
            <div
              block={ this.className }
              elem="PriceWrapper"
            >
                <ProductPrice
                  price={ productPrice }
                  priceType={ type }
                  tierPrices={ priceTiers }
                  isPreview={ isPreview }
                  mix={ { block: this.className, elem: 'Price' } }
                  customerPrice={ customer_price }
                  isDailyPromo={ isDailyPromo }
                />
            </div>
        );
    }

    renderQuantityChanger() {
        const {
            quantity,
            minQuantity,
            maxQuantity,
            setQuantity,
            inStock,
            product: { type_id, stock_item },
        } = this.props;

        if (type_id === ProductType.GROUPED) {
            return null;
        }

        const incQty = stock_item?.qty_increments || 1;

        return (
            <Suspense fallback={ null }>
                <FieldContainer
                  type={ FieldType.NUMBER_WITH_CONTROLS }
                  attr={ {
                      id: 'item_qty',
                      name: 'item_qty',
                      defaultValue: incQty > 1 ? incQty : quantity,
                      max: maxQuantity,
                      min: incQty > 1 ? incQty : minQuantity,
                      customRange: incQty,
                  } }
                  validationRule={ {
                      inputType: ValidationInputTypeNumber.NUMERIC,
                      isRequired: true,
                      range: {
                          min: minQuantity,
                          max: maxQuantity,
                      },
                  } }
                  isDisabled={ !inStock }
                  mix={ { block: this.className, elem: 'Qty' } }
                  events={ { onChange: setQuantity } }
                  validateOn={ ['onChange'] }
                />
            </Suspense>
        );
    }
}

export default ProductComponent;
