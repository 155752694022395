/* eslint-disable max-len */
import {
    CartIconComponent as SourceCartIconComponent,
} from 'SourceComponent/CartIcon/CartIcon.component';

/** @namespace Satisfly/Component/CartIcon/Component */
export class CartIconComponent extends SourceCartIconComponent {
    render() {
        return (
            <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Ikony" clipPath="url(#clip0_1884_3527)">
                <path id="Icon" d="M1.5 1.375H5.5L8.18 14.765C8.27144 15.2254 8.52191 15.639 8.88755 15.9333C9.25318 16.2276 9.7107 16.384 10.18 16.375H19.9C20.3693 16.384 20.8268 16.2276 21.1925 15.9333C21.5581 15.639 21.8086 15.2254 21.9 14.765L23.5 6.375H6.5M10.5 21.375C10.5 21.9273 10.0523 22.375 9.5 22.375C8.94772 22.375 8.5 21.9273 8.5 21.375C8.5 20.8227 8.94772 20.375 9.5 20.375C10.0523 20.375 10.5 20.8227 10.5 21.375ZM21.5 21.375C21.5 21.9273 21.0523 22.375 20.5 22.375C19.9477 22.375 19.5 21.9273 19.5 21.375C19.5 20.8227 19.9477 20.375 20.5 20.375C21.0523 20.375 21.5 20.8227 21.5 21.375Z" stroke="#051B28" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </g>
                <defs>
                <clipPath id="clip0_1884_3527">
                <rect width="24" height="24" fill="white" transform="translate(0.5 0.375)" />
                </clipPath>
                </defs>
            </svg>

        );
    }
}

export default CartIconComponent;
