import { connect } from 'react-redux';

import {
    mapDispatchToProps, mapStateToProps, ProductCardContainer as SourceProductCardContainer,
} from 'SourceComponent/ProductCard/ProductCard.container';

/** @namespace Satisfly/Component/ProductCard/Container */
export class ProductCardContainer extends SourceProductCardContainer {
    static defaultProps = {
        ...super.defaultProps,
        isWidget: false,
        isProductPage: false,
        isVariant: false,
        isDailyPromo: false,
        isSubstituteProduct: false,
        isFrequentlyBoughtItemsPackage: false,
        isFrequentlyBoughtItemsView: false,
        isSimilarProduct: false,
        defaultQty: 0,
        setVariantQuantity: () => {},
        setVariantPackageQuantity: () => {},
    };

    state = {
        ...this.state,
        variantsCollapsed: true,
    };

    containerProps() {
        const { variantsCollapsed } = this.state;
        const {
            isWidget,
            isProductPage,
            isVariant,
            isDailyPromo,
            isSubstituteProduct,
            isFrequentlyBoughtItemsPackage,
            isFrequentlyBoughtItemsView,
            isSimilarProduct,
            defaultQty,
        } = this.props;

        return {
            ...super.containerProps(),
            isWidget,
            isProductPage,
            variantsCollapsed,
            isVariant,
            isDailyPromo,
            isSubstituteProduct,
            isFrequentlyBoughtItemsPackage,
            isFrequentlyBoughtItemsView,
            isSimilarProduct,
            defaultQty,
        };
    }

    containerFunctions = {
        ...this.containerFunctions,
        toggleVariants: this.toggleVariants.bind(this),
        setVariantQuantity: this.setVariantQuantity.bind(this),
        setVariantPackageQuantity: this.setVariantPackageQuantity.bind(this),
    };

    toggleVariants() {
        const { variantsCollapsed } = this.state;

        this.setState({ variantsCollapsed: !variantsCollapsed });
    }

    setVariantQuantity(itemCount) {
        const { setVariantQuantity, product } = this.props;

        setVariantQuantity(itemCount, product);
    }

    setVariantPackageQuantity(itemCount) {
        const { setVariantPackageQuantity, product } = this.props;

        setVariantPackageQuantity(itemCount, product);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardContainer);
