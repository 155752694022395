import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { LOADING_TIME } from 'Route/CategoryPage/CategoryPage.config';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    ProductPageContainer as SourceProductPageContainer,
} from 'SourceRoute/ProductPage/ProductPage.container';
import { getIndexedProducts, getKeyAttributesWithValues } from 'Util/Product';
import { prepareQuery } from 'Util/Query';
import { debounce } from 'Util/Request/Debounce';
import { executeGet } from 'Util/Request/Request';

import { EXCLUDED_ATTRIBUTES } from './ProductPage.config';

/** @namespace Satisfly/Route/ProductPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    // TODO extend mapStateToProps
});

/** @namespace Satisfly/Route/ProductPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    // TODO extend mapDispatchToProps
});

/** @namespace Satisfly/Route/ProductPage/Container */
export class ProductPageContainer extends SourceProductPageContainer {
    state = {
        ...this.state,
        hoveredItem: null,
        substituteProducts: [],
    };

    containerFunctions = {
        ...this.containerFunctions,
        setHoveredItem: this.setHoveredItem.bind(this),
    };

    componentDidUpdate(prevProps) {
        const {
            isOffline,
            productSKU,
            product,
        } = this.props;

        const {
            productSKU: prevProductSKU,
            product: prevProduct,
        } = prevProps;

        const { sku: stateSKU } = history?.location?.state?.product || {};

        if (isOffline) {
            debounce(this.setOfflineNoticeSize, LOADING_TIME)();
        }

        /**
         * We should also update product based data if, the URL
         * rewrite SKU has changed to matching the product history SKU
         * one. At this point there could be sufficient data for
         * some updates (i.e. header state).
         */
        if (
            productSKU !== prevProductSKU
            && stateSKU === productSKU
        ) {
            this.updateHeaderState();
        }

        /**
         * If product object was changed => it is loaded => we need to
         * update product specific information, i.e. breadcrumbs.
         */
        if (JSON.stringify(product) !== JSON.stringify(prevProduct)) {
            this.updateBreadcrumbs();
            this.updateHeaderState();
            this.updateMeta();
        }

        this._addToRecentlyViewedProducts();

        const { substituteProducts } = this.state;

        if (product && Object.keys(product)?.length > 0 && product?.substitute_products?.length > 0 && substituteProducts.length === 0) {
            this.getSubstituteProducts();
        }
    }

    clearSubstituteProducts() {
        this.setState({ substituteProducts: [] });
    }

    getSubstituteProducts() {
        const { product: { substitute_products } } = this.props;
        const items = substitute_products.map((item) => {
            const { sku } = item;

            return sku;
        });

        if (items.length === 0) {
            return;
        }

        const options = {
            args: {
                filter: {
                    productsSkuArray: items,
                },
            },
        };

        const query = [ProductListQuery.getQuery(options)];

        executeGet(prepareQuery(query), 'substituteProducts')
            .then(
                /** @namespace Satisfly/Route/ProductPage/Container/ProductPageContainer/getSubstituteProducts/then/catch/executeGet/then */
                ({ products: { items } }) => this.setState({ substituteProducts: getIndexedProducts(items) }),
            )
            .catch(
                /** @namespace Satisfly/Route/ProductPage/Container/ProductPageContainer/getSubstituteProducts/then/catch */
                // eslint-disable-next-line no-console
                (e) => console.log('e', e),
            );
    }

    containerProps() {
        const { hoveredItem, substituteProducts } = this.state;

        return {
            ...super.containerProps(),
            hoveredItem,
            substituteProducts,
        };
    }

    setHoveredItem(item) {
        const { hoveredItem } = this.state;

        if (hoveredItem !== item) {
            this.setState({ hoveredItem: item });
        }
    }

    isProductAttributesTabEmpty() {
        const dataSource = this.getDataSource();
        const attributes = Object.keys(getKeyAttributesWithValues(dataSource) || {});
        const filteredAttributes = attributes.filter((attr) => EXCLUDED_ATTRIBUTES.indexOf(attr) === -1);

        return filteredAttributes.length === 0;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPageContainer);
