/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { PureComponent } from 'react';

import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import { ChevronIconComponentProps } from 'Component/ChevronIcon/ChevronIcon.type';
import { ReactElement } from 'Type/Common.type';

import './ArrowIcon.style';

/** @namespace Satisfly/Component/ArrowIcon/Component */
export class ArrowIconComponent extends PureComponent<ChevronIconComponentProps> {
    static defaultProps: Partial<ChevronIconComponentProps> = {
        direction: Directions.RIGHT,
    };

    render(): ReactElement {
        const { direction } = this.props;

        return (
            <svg
              block="ArrowIcon"
              mods={ { direction } }
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
                <path d="M5 12H19M19 12L12 5M19 12L12 19" stroke="#03598F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        );
    }
}

export default ArrowIconComponent;
