/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa-theme
 * @link https://github.com/scandipwa/scandipwa-theme
 */

export const AvailabilityMap = {
    Dostępne: 'green',
    'Do 4 dni': 'orange',
    'Do 7 dni': 'orange',
    'Do 14 dni': 'orange',
    'Do 30 dni': 'orange',
    'Pod zamówienie': 'blue',
    Wycofane: 'grey',
};

export const AVAILABILITY_CODE = 'Dostępne';
