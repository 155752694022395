/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ProductCard from 'Component/ProductCard';
import { showNotification } from 'Store/Notification/Notification.action';
import { ReactComponent as ArrowDown } from 'Style/icons/arrow-down.svg';
import { ReactComponent as ArrowUp } from 'Style/icons/arrow-up.svg';
import { ItemShape } from 'Type/ProductList.type';

import { VISIBLE_VARIANTS } from './ProductCardVariants.config';

import './ProductCardVariants.style';

/** @namespace Satisfly/Component/ProductCardVariants/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Satisfly/Component/ProductCardVariants/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showNotification: (type, title, error) => dispatch(showNotification(type, title, error)),
});

/** @namespace Satisfly/Component/ProductCardVariants/Container */
export class ProductCardVariantsContainer extends PureComponent {
    static defaultProps = {
        items: [],
        isProductPage: false,
        setHoveredItem: false,
    };

    state = {
        expanded: false,
    };

    static propTypes = {
        items: PropTypes.arrayOf(PropTypes.shape({
            position: PropTypes.number,
            qty: PropTypes.number,
            product: ItemShape,
        })),
        isProductPage: PropTypes.bool,
        setHoveredItem: PropTypes.func,
        setVariantQuantity: PropTypes.func.isRequired,
        setVariantPackageQuantity: PropTypes.func.isRequired,
    };

    render() {
        const { expanded } = this.state;
        const {
            items, isProductPage, setHoveredItem, setVariantQuantity, setVariantPackageQuantity,
        } = this.props;

        return (
            <div block="ProductCardVariant" elem="WrapperComponent">
                <div>
                    { ((isProductPage && items.length > VISIBLE_VARIANTS && !expanded && items.slice(0, VISIBLE_VARIANTS)) || items).map((item, i) => (
                        <div
                          onMouseOver={ (e) => {
                              e.stopPropagation();

                              if (item.product.media_gallery_entries?.[0] && setHoveredItem) {
                                  setHoveredItem(item.product.media_gallery_entries[0]);
                              }
                          } }
                          onMouseLeave={ (e) => {
                              e.stopPropagation();

                              if (setHoveredItem) {
                                  setHoveredItem(null);
                              }
                          } }
                        >
                            <ProductCard
                              key={ i.toString() }
                              product={ item.product }
                              layout="list"
                              isProductPage={ isProductPage }
                              isVariant
                              defaultQty={ item.qty }
                              setVariantQuantity={ setVariantQuantity }
                              setVariantPackageQuantity={ setVariantPackageQuantity }
                            />
                        </div>
                    )) }
                </div>
                { isProductPage && items.length > VISIBLE_VARIANTS && (
                    <span block="ProductCardVariant" elem="SeeMore">
                        <button onClick={ () => this.setState({ expanded: !expanded }) }>
                            { expanded ? __('Show less') : __('Show more') }
                            { ' ' }
                            { items.length - VISIBLE_VARIANTS }
                            { ' ' }
                            { expanded ? <ArrowUp /> : <ArrowDown /> }
                        </button>
                    </span>
                ) }
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductCardVariantsContainer);
