/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import CartIcon from 'Component/CartIcon';
import Loader from 'Component/Loader';
import {
    AddToCartComponent as SourceAddToCartComponent,
} from 'SourceComponent/AddToCart/AddToCart.component';

import './AddToCart.override.style';

/**
 * Button for adding product to Cart
 * @class AddToCart
 * @namespace Satisfly/Component/AddToCart/Component */
export class AddToCartComponent extends SourceAddToCartComponent {
    renderCartIcon() {
        const {
            isIconEnabled,
            isAdding,
        } = this.props;

        if (!isIconEnabled) {
            return null;
        }

        if (isAdding) {
            return <Loader isButton isLoading />;
        }

        return <CartIcon />;
    }

    render() {
        const {
            mix,
            layout,
            isDisabled,
            isAdding,
            handleButtonClick,
            onlyIcon,
            label,
            isDailyPromo,
        } = this.props;

        return (
            <button
              onClick={ handleButtonClick }
              block="Button AddToCart"
              mix={ mix }
              mods={ { layout, onlyIcon, isDailyPromo } }
              disabled={ isDisabled || isAdding }
            >
                <span block="AddToCart" elem="Label">{ label }</span>
                { this.renderCartIcon() }
                { !onlyIcon ?? <span>{ isAdding ? __('Adding...') : __('Add to cart') }</span> }
            </button>
        );
    }
}

export default AddToCartComponent;
