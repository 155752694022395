import {
    ProductPriceContainer as SourceProductPriceContainer,
} from 'SourceComponent/ProductPrice/ProductPrice.container';

import { DisplayProductPricesInCatalog } from './ProductPrice.config';

/** @namespace Satisfly/Component/ProductPrice/Container */
export class ProductPriceContainer extends SourceProductPriceContainer {
    static defaultProps = {
        ...super.defaultProps,
        displayTaxInPrice: DisplayProductPricesInCatalog.EXCL_TAX,
        isSimilarProduct: false,
        customerPrice: {},
        isDailyPromo: false,
        isPdp: false,
        isWidget: false,
    };

    containerProps() {
        const {
            isWidget, isSimilarProduct, customerPrice, isDailyPromo, isPdp,
        } = this.props;

        return {
            ...super.containerProps(),
            isWidget,
            isSimilarProduct,
            customerPrice,
            isDailyPromo,
            isPdp,
        };
    }
}

export default ProductPriceContainer;
