import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    ProductGalleryContainer as SourceProductGalleryContainer,
} from 'SourceComponent/ProductGallery/ProductGallery.container';

/** @namespace Satisfly/Component/ProductGallery/Container */
export class ProductGalleryContainer extends SourceProductGalleryContainer {
    containerProps() {
        const {
            hoveredItem,
        } = this.props;

        return {
            ...super.containerProps(),
            hoveredItem,
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductGalleryContainer);
